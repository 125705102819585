;(function($, window, undefined) {

    $(function() {

        // Mobile Menu Stuff
        $(window).on("resize", function() {

            if ($(window).width() >= 768) {

                $(".mobile-menu").removeClass("open");
                $(".hamburger").removeClass("open");

            }

        });
        $(".hamburger").on("click", function() {

            $(this).toggleClass("open");
            $(".mobile-menu").toggleClass("open");

        });
        // End Mobile Menu Stuff

        // Radio Stuff
        $("input[type=radio]").closest("label").addClass("radio-label");
        $("input[type=radio]").each(function() {

            if (!$(this).is(":checked")) {

                $(this).closest("label").removeClass("selected");

            } else {

                $(this).closest("label").addClass("selected");

            }

        });
        $("body").on("change", "input[type=radio]", function() {

            $("input[type=radio][name=" + $(this).attr("name") + "]").each(function() {

                if (!$(this).is(":checked")) {

                    $(this).closest("label").removeClass("selected");

                } else {

                    $(this).closest("label").addClass("selected");

                }

            });

        });
        // End Radio Stuff

    });

})(jQuery, window);
